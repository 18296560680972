'use client';

import { useLocale } from 'next-intl';
import { useEffect, useState } from 'react';

const useAppStoreUrl = (): string => {
  const locale = useLocale();
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const googlePlayStoreUrl = `https://play.google.com/store/apps/details?id=com.ouraring.oura&hl=${locale}&gl=US&pli=1`;
    const appleAppStoreUrl = `https://apps.apple.com/${locale}/app/oura/id1043837948`;

    if (window.navigator.userAgent.toLowerCase().indexOf('android') !== -1) {
      return setUrl(googlePlayStoreUrl);
    } else {
      return setUrl(appleAppStoreUrl);
    }
  }, [locale]);

  return url;
};

export default useAppStoreUrl;
