'use client';

import { redirect } from 'next/navigation';

import useAppStoreUrl from '@/app/hooks/useAppStoreUrl';

const Page = (): JSX.Element => {
  const appStoreUrl = useAppStoreUrl();

  if (appStoreUrl) redirect(appStoreUrl);

  return <></>;
};

export default Page;
